<template>
  <div id="userLayout" :class="['user-layout-wrapper']">
    <div class="container">
      <div class="user-layout-content">
        <div class="top">
          <div class="header">
            <a href="/">
              <img
                src="../assets/image/logo.svg"
                class="logo"
                alt="logo"
              />
            </a>
          </div>
          <div class="desc">
            ZENITH WARRANTY MP CMS
          </div>
        </div>
        <router-view />
      </div>
      <div class="footer">
        <div class="links">
          <a href="#" target="_blank">ZENITH</a>
        </div>
        <div class="copyright">
          Copyright &copy; 2020 ZENITH
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserLayout",
  mounted() {
    document.body.classList.add("userLayout");
  },
  beforeDestroy() {
    document.body.classList.remove("userLayout");
  }
};
</script>

<style lang="less" scoped>
#userLayout.user-layout-wrapper {
  height: 100%;

  &.mobile {
    .container {
      .main {
        max-width: 368px;
        width: 98%;
      }
    }
  }

  .container {
    width: 100%;
    min-height: 100%;
    background: #f0f2f5 url("../assets/image/background.svg") no-repeat 50%;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    //padding: 50px 0 84px;
    position: relative;
    .user-layout-content {
      flex: 1;
      padding: 32px 0 24px;
      height: 100%;
      .top {
        text-align: center;
        .header {
          height: 44px;
          line-height: 44px;
          margin-top: 15%;
          .badge {
            position: absolute;
            display: inline-block;
            line-height: 1;
            vertical-align: middle;
            margin-left: -12px;
            margin-top: -10px;
            opacity: 0.8;
          }
          .logo {
            height: 44px;
            vertical-align: top;
            margin-right: 16px;
            border-style: none;
          }
          .title {
            font-size: 33px;
            color: rgba(0, 0, 0, 0.85);
            font-family: Avenir, "Helvetica Neue", Arial, Helvetica, sans-serif;
            font-weight: 600;
            position: relative;
            top: 2px;
          }
        }
        .desc {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          margin-top: 12px;
          margin-bottom: 40px;
        }
      }
      .main {
        min-width: 260px;
        width: 368px;
        margin: 0 auto;
      }
    }
    .footer {
      // position: absolute;
      width: 100%;
      bottom: 0;
      padding: 0 16px;
      margin: 48px 0 24px;
      text-align: center;
      .links {
        margin-bottom: 8px;
        font-size: 14px;
        a {
          color: rgba(0, 0, 0, 0.45);
          transition: all 0.3s;
          &:not(:last-child) {
            margin-right: 40px;
          }
        }
      }
      .copyright {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
      }
    }
    a {
      text-decoration: none;
    }
  }
}
</style>
